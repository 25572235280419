







import Vue from 'vue'
export default Vue.extend({
  props:{
      label:{
          type: String,
          required: true
      },
      value:{
          type: String,
          required: false
      },
      id:{
          type: Number,
          required: false
      }
  },
  data(){
      return{
          clicked: false
        }
  },
  methods:{
      buttonClick(){
          this.clicked = !this.clicked
          this.$emit('button-action', {
              buttonValue: this.value,
              buttonClicked: this.clicked,
              buttonID: this.id
          })
      }
  }
    
})
