














import Vue from "vue";
import PoloTable from './polo-table.vue'
import PoloTableControlPanel from "./polo-table-control-panel.vue";

export default Vue.extend({
  name: 'TableContainer',
  props: {
      endpoint:{
          type: String,
          required: true
      }
  },
  components: {
    PoloTable,
    PoloTableControlPanel
  },
  
  data(){
    return{
      tableProps:[
              {
                label: 'Allocated',
                value: 'allocated',
                show: true  
              },
              {
                label: 'World Difficulty',
                value: 'worldTier',
                show: true  
              },
              {
                label: 'Total Player Count',
                value: 'totalPlayerCount',
                show: true  
              },
              {
                label: 'In-Flight Players Count',
                value: 'inFlightPlayersCount',
                show: true  
              },
              {
                label: 'Connected Players Count',
                value: 'connectedPlayersCount',
                show: true  
              },
              {
                label: 'Max Player Count',
                value: 'maxPlayerCount',
                show: true  
              },
              {
                label: 'DNS',
                value: 'dns',
                show: true  
              },
              {
                label: 'Game Mode',
                value: 'gameMode',
                show: true  
              },
              {
                label: 'Region',
                value: 'region',
                show: true  
              },
              {
                label: 'Git SHA',
                value: 'gitSHA',
                show: true
              }
            ]
    } 
  },
  methods:{
    toggleColumn(column: any){
      var val: number = column.buttonID
      this.tableProps[val].show = !column.buttonClicked
    }
  }
})
