






































import Vue from "vue";
import PoloTableContainer from "./polo-table-container.vue";
import PoloToggleButton from "./polo-toggle-button.vue";
export default Vue.extend({
  components: {
    PoloToggleButton,
    PoloTableContainer,
  },

  data() {
    return {
      buttons: {
        alpha: {
          show: true,
        },
        staging: {
          show: true,
        },
        prod: {
          show: true,
        },
      },
    };
  },
  methods: {
    buttonClick(button: any) {
      if (button.buttonValue === "alpha") {
        this.buttons.alpha.show = !button.buttonClicked;
      } else if (button.buttonValue === "staging") {
        this.buttons.staging.show = !button.buttonClicked;
      } else {
        this.buttons.prod.show = !button.buttonClicked;
      }
    },
  },
});
