
















import Vue from 'vue'
import PoloToggleButton from "./polo-toggle-button.vue"
export default Vue.extend({
    name: 'ControlPanel',
    components:{
        PoloToggleButton
    },
    data(){
        return {
            selected: null,
            checkedName: null
        }
    },
    methods: {
        showToggled(someInput: any){
            this.$emit('toggle-column', someInput)
        }
    }
})
