<template>
  <div>
    <vue-good-table
      :columns="this.poloColumns"
      :rows="this.poloRows"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'totalPlayerCount', type: 'desc'}
      }"
      styleClass="vgt-table striped condensed"
    />
  </div>
</template>

<script >
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
    name: 'PoloTable',
    components:{
      VueGoodTable
    },
    props:{
      endpoint:{
        type: String,
        required: true
      },
      tableProps:{
        type: Array,
        required: true
      }
    },
    data() {
        return {
            poloJSON: null,
        };
    },
    computed:{
      //Dynamically generate a 'table rows' array for good-table based on parsed JSON data from polo endpoint
        poloRows(){
            var serverDataArray = JSON.parse(this.poloJSON)
            if (serverDataArray !== null){
                for(var i = 0; i < serverDataArray.length; i++){
                    var obj = serverDataArray[i]
                    obj['id'] = i
                    if(obj.gameMode === 'hub'){
                        obj.worldTier = obj.worldTier || '0'
                    }
                }
                return serverDataArray
            }
            else{
              //This is mainly to provide good-table with a non-null array which eliminates cosole error messages,
              //since it will initially try to construct a table with the poloRows computed component before the httpRequest in mounted() can be completed
              return [{empty: 'empty'}]
            }
            
        },
        //Dynamically generate 'table columns' array for good-table based on the buttons
        // that have been toggled in the control panel and passed through tableProps
        poloColumns(){
          var columns = []
          for(var i = 0; i < this.tableProps.length; i++){
            if(this.tableProps[i].show == true){
              var obj = {label: this.tableProps[i].label, field: this.tableProps[i].value}
              columns.push(obj)
            }
          }
          return columns
        }
    },
  mounted(){
    var data = this
    var endpointURL
    if(this.endpoint == 'local') {
      endpointURL = 'http://localhost:10000/listings'    
    } else {
      endpointURL = 'https://' + this.endpoint + '-polo.bitfox.dev/listings'    
    }
    const poloRequest = new XMLHttpRequest()
    poloRequest.onreadystatechange = function(){
      if (this.readyState == 4 && this.status == 200){
        data.poloJSON = poloRequest.responseText
      }          
    }
    poloRequest.open('GET', endpointURL, true)
    poloRequest.send()
  }
}

</script>

<style>
* {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
</style>